import { useState } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { getFirebase } from "../firebase";
import { setDoc, doc } from "firebase/firestore";
import { useAuth } from "../hooks/useAuth";
import RadioButton from "../components/RadioButton";
import Button from "../components/Button";
import Input from "../components/Input";
import AvatarUploader from "../components/AvatarUploader";
import useUploadImage from "../hooks/useUploadImage";

const StyledPage = styled.div`
  height: 100vh;
  padding: 24px;

  overflow: hidden;
  form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    overflow: scroll;
    padding-bottom: 96px;
  }
  .actions {
    display: flex;
    flex-direction: row-reverse;
    padding: 40px 24px 24px 24px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(transparent, var(--neutral-100) 25%);
    gap: 8px;
    > * {
      flex: 1 1 0;
    }
  }
`;

export default function CreatePetPage() {
  const {
    state: { pet },
  } = useLocation();

  const [form, setForm] = useState({
    photoURL: pet.photoURL || "",
    name: pet.name || "",
    species: pet.species || "",
    breed: pet.breed || "",
    birthday: pet.birthday || "",
    neutered: pet.neutered || "",
    chipNumber: pet.chipNumber || "",
    sex: pet.sex || "",
  });

  const navigate = useNavigate();

  const { firestore } = getFirebase();
  const { user } = useAuth();
  const [blob, setBlob] = useState("");
  const { uploadImage } = useUploadImage();

  const handleInput = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const createPet = async (data) => {
      const petRef = doc(firestore, "users", user.uid, "pets", form.name);
      setDoc(petRef, data, { merge: true });
    };

    if (blob) {
      const path = `${user.uid}/${form.name}/profile.png`;
      const { downloadURL } = await uploadImage(path, blob, "image/png");
      createPet({ ...form, photoURL: downloadURL });
    } else {
      createPet(form);
    }

    navigate("/profile");
  };

  const handleCancel = () => {
    navigate("/profile");
  };

  const {
    name,
    breed,
    species,
    birthday,
    neutered,
    chipNumber,
    sex,
    photoURL,
  } = form;
  return (
    <StyledPage>
      <form onSubmit={handleSubmit}>
        <AvatarUploader blob={blob} setBlob={setBlob} photoURL={photoURL} />
        <Input
          label="名字"
          name="name"
          value={name}
          onChange={handleInput}
          placeholder="請輸入名字"
          required
        />
        <RadioButton.Group>
          <RadioButton
            label="貓"
            name="species"
            value="cat"
            onChange={handleInput}
            checked={species === "cat"}
            required
          />

          <RadioButton
            label="狗"
            name="species"
            id="dog"
            value="dog"
            checked={species === "dog"}
            onChange={handleInput}
            required
          />
        </RadioButton.Group>

        <Input
          label="品種"
          name="breed"
          value={breed}
          onChange={handleInput}
          placeholder="請輸入品種"
        />

        <RadioButton.Group>
          <RadioButton
            label="公"
            name="sex"
            value="male"
            checked={sex === "male"}
            onChange={handleInput}
            required
          />

          <RadioButton
            label="母"
            name="sex"
            id="female"
            value="female"
            checked={sex === "female"}
            onChange={handleInput}
            required
          />
        </RadioButton.Group>

        <Input
          label="出生日期"
          type="date"
          name="birthday"
          value={birthday}
          onChange={handleInput}
        />
        <RadioButton.Group>
          <RadioButton
            label="已結紮"
            name="neutered"
            value={true}
            checked={!!neutered}
            onChange={handleInput}
          />
          <RadioButton
            label="未結紮"
            name="neutered"
            value={false}
            checked={!!neutered}
            onChange={handleInput}
          />
        </RadioButton.Group>

        <Input
          label="晶片號碼"
          name="chipNumber"
          value={chipNumber}
          onChange={handleInput}
          placeholder="請輸入晶片號碼"
        />
        <div className="actions">
          <Button label="送出" type="submit" />
          <Button label="取消變更" onClick={handleCancel} variant="secondary" />
        </div>
      </form>
    </StyledPage>
  );
}
