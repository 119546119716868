import React, { useRef, useState } from "react";
import styled from "styled-components";
import AvatarEditor from "react-avatar-editor";
import Button from "./Button";

const StyledModal = styled.div`
  display: ${(props) => (props.show ? "block" : "none")};
  position: absolute;
  inset: 0;
  background-color: var(--neutral-300);
  z-index: 10;
  overscroll-behavior: contain;
  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    height: 100%;
    .button-group {
      width: 75%;
    }
    .scale-input {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 75%;
      input {
        width: 100%;
      }
      label {
        flex-shrink: 0;
      }
    }
  }
`;

export default function AvatarEditorModal({
  show,
  inputURL,
  setBlob,
  setShowModal,
}) {
  const editor = useRef(null);
  const [scale, setScale] = useState(1);
  const setURL = async () => {
    if (editor) {
      const dataUrl = editor.current
        .getImageScaledToCanvas()
        .toDataURL("image/png", 0.1);
      const result = await fetch(dataUrl);
      const blob = await result.blob();
      setBlob(blob);
    }
  };
  return (
    <StyledModal show={show} className="avatar-editor-modal">
      <div className="modal-content">
        <AvatarEditor
          image={inputURL}
          ref={editor}
          borderRadius={100}
          scale={scale}
        />
        <div className="scale-input">
          <label htmlFor="scale">放大</label>
          <input
            type="range"
            id="scale"
            name="volume"
            min={0.8}
            max={3}
            step={0.1}
            value={scale}
            onChange={(e) => setScale(e.target.value)}
          />
        </div>

        <Button.Group>
          <Button
            onClick={() => {
              setShowModal(false);
            }}
            label="取消"
            variant="secondary"
          />
          <Button
            onClick={() => {
              setURL().then(setShowModal(false));
            }}
            label="確定"
          />
        </Button.Group>
      </div>
    </StyledModal>
  );
}
