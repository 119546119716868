import React, { useState, useEffect } from "react";
import AvatarEditorModal from "../components/AvatarEditorModal";
import styled from "styled-components";

const AvatarPreview = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  input {
  }
  label {
    padding: 0.5rem 0.8rem;
    border-radius: 100px;
    background-color: var(--neutral-300);
    color: var(--neutral-700);
    font-size: 14px;
    cursor: pointer;
  }
  .avatar {
    height: 72px;
    width: 72px;
    border-radius: 100%;
    background-color: var(--neutral-300);
    background-size: contain;
    background-repeat: no-repeat;
  }
`;

export default function UploadAvatar({ blob, setBlob, photoURL }) {
  const [file, setFile] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [inputURL, setInputURL] = useState("");

  useEffect(() => {
    if (file) {
      const url = URL.createObjectURL(file);
      setInputURL(url);
      setShowModal(true);
      return function cleanup() {
        URL.revokeObjectURL(url);
      };
    }
  }, [file]);

  const handleFile = (e) => {
    setFile(e.target.files[0]);
  };

  const preview = blob ? URL.createObjectURL(blob) : photoURL;

  return (
    <AvatarPreview>
      <AvatarEditorModal
        show={showModal}
        inputURL={inputURL}
        setBlob={setBlob}
        setShowModal={setShowModal}
      />
      <div
        className="avatar"
        style={{ backgroundImage: `url(${preview})` }}
      ></div>
      <input
        type="file"
        id="upload"
        accept="image/*"
        onChange={(e) => handleFile(e)}
        hidden
      />
      <label htmlFor="upload">上傳照片</label>
    </AvatarPreview>
  );
}
