export const config = {
  firebase: {
    apiKey: "AIzaSyDsFovS2e3RHqr3fXeZFj4VLSSdIBaF2c0",
    authDomain: "tailtale-app.firebaseapp.com",
    projectId: "tailtale-app",
    storageBucket: "tailtale-app.appspot.com",
    messagingSenderId: "32282183875",
    appId: "1:32282183875:web:5adb9fc7b3cc3b42f87a76",
    measurementId: "G-XKCZPD2D50",
  },
};
