import React, { useState, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import styled from "styled-components";
import dayjs from "dayjs";
import "dayjs/locale/zh-tw";
import { Calendar, Heart, BookOpen } from "react-feather";
import { Modal, Drawer } from "@geist-ui/core";
import CalendarView from "../components/Calendar";
import IconButton from "../components/IconButton";
import FoodRecordItem from "../components/FoodRecordItem";
import MenuItem from "../components/MenuItem";
import ActionButton from "../components/ActionButton";
import { getFirebase } from "../firebase";
import { onSnapshot, doc } from "firebase/firestore";
import { useAuth } from "../hooks/useAuth";

const StyledPage = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
      letter-spacing: 2px;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
    overflow-y: scroll;
    padding-bottom: 128px;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      width: 0px;
    }
  }
  h2 {
    font-size: 14px;
    font-weight: 500;
  }
  .note {
    padding: 16px 24px;
    background-color: #fff;
    border-radius: 24px;
    white-space: pre-wrap;
    line-height: 1.6;
  }
  .empty-message {
    margin-top: 120px;
    flex: 1;
    color: var(--neutral-300);
    text-align: center;
  }
  .menu-items {
  }
  .total-calories {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    font-size: 14px;
    background-color: #fff;
    border-radius: 16px;
  }
`;

const StyledMenuItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

export default function DiaryPage() {
  const [selectedDay, setSelectedDay] = useState(dayjs().format("YYYY-MM-DD"));
  const [selectedDayData, setSelectedDayData] = useState({});
  const [totalCalories, setTotalCalories] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const navigate = useNavigate();
  const { firestore } = getFirebase();
  const { user } = useAuth();
  const currentPet = useOutletContext();

  useEffect(() => {
    const diaryCol = doc(
      firestore,
      "users",
      user.uid,
      "pets",
      currentPet,
      "diaries",
      selectedDay
    );

    const unsubscribe = onSnapshot(diaryCol, (doc) => {
      // console.log(doc.data());
      if (doc.data()) {
        setSelectedDayData(doc.data());
      } else {
        setSelectedDayData({});
      }
    });

    return () => {
      unsubscribe();
    };
  }, [selectedDay, currentPet]);

  useEffect(() => {
    if (selectedDayData?.foodRecord) {
      const calories = selectedDayData.foodRecord.reduce((acc, curr) => {
        return acc + (curr.calories * +curr.portion) / 100;
      }, 0);
      setTotalCalories(calories);
    }
  }, [selectedDayData, selectedDay]);

  const renderEmptyContent = () => {
    return <div className="empty-message">今天還沒有紀錄 </div>;
  };
  const renderContent = () => {
    const { note, foodRecord = [] } = selectedDayData;
    return (
      <>
        {note ? <div className="note">{note}</div> : null}
        <h2>食物紀錄</h2>
        {foodRecord.length !== 0 ? (
          <>
            {foodRecord.map((info, i) => (
              <FoodRecordItem info={info} key={i} />
            ))}
            <div className="total-calories">
              <span>總共攝取卡路里</span>
              <span>{totalCalories}kcal</span>
            </div>
          </>
        ) : (
          <div className="empty-message">尚未添加食物紀錄</div>
        )}
      </>
    );
  };
  return (
    <StyledPage className="diary">
      <Modal visible={showModal} onClose={() => setShowModal(false)}>
        <CalendarView
          setSelectedDay={setSelectedDay}
          setShowModal={setShowModal}
          selectedDay={selectedDay}
        />
      </Modal>
      <div className="header">
        <h1>{`${dayjs(selectedDay).locale("zh-tw").format("MMMD")}日`}</h1>
        <IconButton
          icon={<Calendar />}
          variant="secondary"
          onClick={() => setShowModal(true)}
        />
      </div>
      <div className="content">
        {selectedDayData == null || Object.keys(selectedDayData).length === 0
          ? renderEmptyContent()
          : renderContent()}
      </div>

      <ActionButton onClick={() => setShowDrawer(true)} />
      <Drawer
        visible={showDrawer}
        onClose={() => setShowDrawer(false)}
        placement="bottom"
      >
        <StyledMenuItem>
          <MenuItem
            icon={<Heart />}
            label="食物"
            onClick={() =>
              navigate("/foods/search", {
                state: {
                  from: "diary",
                  currentPet: currentPet,
                  date: selectedDay,
                },
              })
            }
          />

          <MenuItem
            icon={<BookOpen />}
            label="日記"
            onClick={() =>
              navigate("diaries/note/add", {
                state: {
                  currentPet: currentPet,
                  date: selectedDay,
                  note: selectedDayData.note,
                },
              })
            }
          />
          {/* <MenuItem icon={<Image />} label="照片" />
          <MenuItem icon={<TrendingUp />} label="數據" /> */}
        </StyledMenuItem>
      </Drawer>
    </StyledPage>
  );
}
