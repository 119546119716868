import React from "react";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Label,
} from "recharts";
import styled from "styled-components";

const StyledDiv = styled.div`
  table {
    /* border: 1px solid red; */
    text-align: center;
    table-layout: fixed;
    width: 100%;
    th {
      font-size: 12px;
      background-color: #eeeeee;
    }
    td {
      font-size: clamp(10px, 4.2vw, 20px);
    }
  }
  h3 {
    margin: 16px 0px 8px;
    font-size: 14px;
    color: var(--neutral-500);
  }
  p {
    font-size: 14px;
  }
`;

export default function FoodNutrition({
  brand,
  product,
  flavor,
  calories,
  foodType,
  ingredient,
  origin,
  weight,
  //%,
  water = 10,
  protein = 10,
  fat = 10,
  carbonhydrate = 10,
  ash = 10,
  fibre = 10,
  calcium = 10,
  phosphorus = 10,
  //IE,
  vitd3,
  //mg,
  taurine,
  zinc,
  manganese,
  iodine,
  vite,
  nonMeatElement,
}) {
  return (
    <StyledDiv>
      <div>
        <h3>主要原料</h3>
        <p>{ingredient.join(",")}</p>
      </div>
      <div>
        <h3>營養組成</h3>
        <table>
          <thead>
            <tr>
              <th>水</th>
              <th>蛋白質</th>
              <th>脂肪</th>
              <th>粗灰分</th>
              <th>粗纖維</th>
              <th>鈣</th>
              <th>磷</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{water}%</td>
              <td>{protein}%</td>
              <td>{fat}%</td>
              <td>{ash}%</td>
              <td>{fibre}%</td>
              <td>{calcium}%</td>
              <td>{phosphorus}%</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div>
        <h3>熱量</h3>
        <p>{calories}</p>
      </div>
      <div>
        <h3>每公斤營養添加</h3>
        <table>
          <thead>
            <tr>
              <th>維他命 D3</th>
              <th>牛磺酸</th>
              <th>鋅</th>
              <th>鎂</th>
              <th>碘</th>
              <th>維他命 E</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{vitd3}</td>
              <td>{taurine}</td>
              <td>{zinc}</td>
              <td>{manganese}</td>
              <td>{iodine}</td>
              <td>{vite}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </StyledDiv>
  );
}
