import React from "react";
import { Modal, Rating } from "@geist-ui/core";
import IconButton from "../components/IconButton";
import FoodNutrition from "../components/FoodNutrition";
import styled from "styled-components";
import { Edit } from "react-feather";

const Title = styled.h1`
  font-size: 22px;
`;

const Subtitle = styled.h2`
  font-size: 14px;
  color: var(--neutral-400);
  padding-bottom: 8px;
`;

const Review = styled.div`
  margin-top: 8px;
  padding: 16px;
  border-radius: 16px;
  background-color: var(--neutral-100);
  font-size: 14px;
  position: relative;

  .rating .icon-box {
    width: 12px;
    height: 12px;
  }
  .icon-button {
    position: absolute;
    right: 0;
    top: 0;
  }
`;

export default function FoodModal({
  visible,
  onClose,
  selectedFood,
  handleEdit,
}) {
  return (
    <Modal visible={visible} onClose={onClose}>
      {console.log(selectedFood)}

      <Modal.Content>
        <Subtitle>
          {selectedFood.food.brand} | {selectedFood.food.product}
        </Subtitle>
        <Title>{selectedFood.food.flavor}</Title>

        <Review>
          <Rating
            value={selectedFood.rating}
            className="food-rating"
            locked={true}
          />
          <p> {selectedFood.comment}</p>
          <IconButton
            label="修改評價"
            icon={<Edit />}
            variant="transparent"
            onClick={() => handleEdit(selectedFood)}
          />
        </Review>
        <FoodNutrition {...selectedFood.food} />
      </Modal.Content>
    </Modal>
  );
}
