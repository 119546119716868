import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useState } from "react";
import { getFirebase } from "../firebase";

export default function useUploadImage() {
  const { storage } = getFirebase();
  const [error, setError] = useState();
  const [uploading, setUploading] = useState();

  const uploadImage = async (path, data, imageType) => {
    return new Promise((resolve, reject) => {
      setUploading(true);
      setError(undefined);
      const uploadTask = uploadBytesResumable(ref(storage, path), data, {
        contentType: imageType,
      });

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          setUploading(false);
          setError(error);
          resolve(undefined);
        },
        () => {
          setUploading(false);
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log("File available at", downloadURL);
            resolve({
              metadata: uploadTask.snapshot.metadata,
              ref: uploadTask.snapshot.ref,
              downloadURL: downloadURL,
            });
          });
        }
      );
    });
  };
  return { uploadImage, uploading, error };
}
